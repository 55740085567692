'use client';

import { analyticsTrack } from '@/util/amplitude';
import { formatQueryParams } from '@/util/queryParams';
import { LoadingButton } from '@magicschool/ui/Buttons/LoadingButton';
import { getStudentUrl } from '@magicschool/utils/nextjs/url';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useStore } from 'features/store';
import NextLink from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthHeaderClient } from '../AuthHeaderClient';
import { LegalInfoClient } from '../LegalInfoClient';
import { RoleStep } from '../RoleStep';
import { SSOClient } from '../SSOClient';
import { supportsEmailProvider } from '../constants';
const SignIn = () => {
  const searchParams = useSearchParams();
  const next = searchParams.get('next') ?? '/tools';
  const deleted = searchParams.has('deleted');
  const intl = useIntl();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const {
    loading,
    setField,
    signIn,
    authProviders,
    roleSelected
  } = useStore(({
    AuthFormStoreData: af
  }) => ({
    loading: af.loading,
    setField: af.setField,
    signIn: af.signIn,
    authProviders: af.authProviders,
    roleSelected: af.roleSelected
  }), []);
  const nextRedirectUrl = `${next}${formatQueryParams(searchParams, ['next'])}`;
  useEffect(() => {
    if (deleted) setField('formAlert')({
      type: 'success',
      messageId: 'profile.delete.success',
      page: 'User:Deleted'
    });
  }, [setField, deleted]);
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    analyticsTrack('SignIn:Clicked');
    signIn(email, password, nextRedirectUrl);
  };
  if (!roleSelected) {
    return <RoleStep />;
  }
  return <Box sx={{
    width: {
      xs: '90%',
      sm: '400px'
    }
  }} data-sentry-element="Box" data-sentry-component="SignIn" data-sentry-source-file="page.tsx">
      <AuthHeaderClient id="auth.educator-sign-in" data-sentry-element="AuthHeaderClient" data-sentry-source-file="page.tsx" />

      <form onSubmit={onSubmit}>
        {supportsEmailProvider(authProviders) && <Box width="100%" flex="1" display="flex" flexDirection="column" gap={1}>
            <TextField size="small" value={email} onChange={e => setEmail(e.target.value)} variant="outlined" type="email" autoComplete="email" placeholder={intl.formatMessage({
          id: 'profile.fields.email'
        })} />

            <Box position="relative">
              <TextField fullWidth size="small" type={showPassword ? 'text' : 'password'} autoComplete="current-password" placeholder={intl.formatMessage({
            id: 'auth.password'
          })} value={password} onChange={e => setPassword(e.target.value)} />
              <Button sx={{
            position: 'absolute',
            right: 2,
            top: '2px'
          }} onClick={() => {
            setShowPassword(!showPassword);
            analyticsTrack('SignIn:TogglePasswordVisibilityClicked', {
              visible: showPassword
            });
          }}>
                <FormattedMessage id={showPassword ? 'input_panel.hide' : 'input_panel.show'} />
              </Button>
            </Box>

            <LoadingButton type="submit" ordinal="secondary" hideContentOnLoad variant={loading ? 'outlined' : 'contained'} loading={loading} disabled={loading}>
              <FormattedMessage id="auth.sign-in" />
            </LoadingButton>
          </Box>}

        <SSOClient page="signin" buttonAction="auth.sign-in" next={nextRedirectUrl} data-sentry-element="SSOClient" data-sentry-source-file="page.tsx" />
      </form>

      <Stack alignItems="center" gap={1} data-sentry-element="Stack" data-sentry-source-file="page.tsx">
        <LegalInfoClient location="SignIn" data-sentry-element="LegalInfoClient" data-sentry-source-file="page.tsx" />
        <Box width="100%" flex="1" display="flex" justifyContent="center" mt={2} gap={2} data-sentry-element="Box" data-sentry-source-file="page.tsx">
          <Link component={NextLink} href={`/auth/signup${next ? `?next=${next}` : ''}`} underline="hover" color="secondary" onClick={() => analyticsTrack('SignIn:CreateAccountClicked')} data-sentry-element="Link" data-sentry-source-file="page.tsx">
            <FormattedMessage id="auth.create-account" data-sentry-element="FormattedMessage" data-sentry-source-file="page.tsx" />
          </Link>
          <Link component={NextLink} href={`/auth/forgotpass${next ? `?next=${next}` : ''}`} underline="hover" color="secondary" onClick={() => analyticsTrack('SignIn:ForgotPasswordClicked')} data-sentry-element="Link" data-sentry-source-file="page.tsx">
            <FormattedMessage id="auth.forgot-password" data-sentry-element="FormattedMessage" data-sentry-source-file="page.tsx" />
          </Link>
        </Box>
        <Link component={NextLink} href={getStudentUrl()} underline="hover" color="secondary" onClick={() => analyticsTrack('SignIn:NotEducatorClicked')} data-sentry-element="Link" data-sentry-source-file="page.tsx">
          <FormattedMessage id="auth.not-educator" data-sentry-element="FormattedMessage" data-sentry-source-file="page.tsx" />
        </Link>
      </Stack>
    </Box>;
};
export default SignIn;